import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useLocation  } from "react-router-dom";
import { Toaster, toast } from 'sonner';

import RootPage from '@pages/root';
import Dashboard from '@pages/dash';
import Blog from "@pages/blog";
import BlogPost from "@pages/blog/post";
import ErrorPage from "@pages/error";
import Projects from "@pages/projects";
import HRM from "@pages/hrm";
import Status from "@pages/status";
import Analytics from "@pages/analytics";
import Weboffice from "@pages/weboffice";

import Cloud from "@pages/cloud";
import CorpForge from "@pages/corpforge";
import Docs from "@pages/docs";
import CorpShop from "@pages/corpshop";
import Drive from "@pages/drive";
import About from "@pages/about";
import STagManager from '@pages/sgtm';

function App() {
  return (
    <Router basename="/">
      <Toaster position="bottom-right" richColors expand={false} />
      <ScrollToTop />
      <Routes>
        <Route path='*' element={<ErrorPage />} />
        <Route path='/' element={<RootPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/hrm" element={<HRM />} />
        <Route path="/status" element={<Status />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/weboffice" element={<Weboffice />} />
        <Route path="/cloud" element={<Cloud />} />
        <Route path="/corpforge" element={<CorpForge />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/corpshop" element={<CorpShop />} />
        <Route path="/drive" element={<Drive />} />
        <Route path="/about" element={<About />} />
        <Route path="/sgtm" element={<STagManager />} />
      </Routes>
    </Router>
  )
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App
