import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import InfoBlock from "@widgets/infoBlock";
import PricingBlock from "@widgets/pricing";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import Logo from '@assets/logo/leecyber.png';
import AccessGif from '@assets/dash-access.gif';
import MobileGif from '@assets/dash-mobile.gif';
import { SmoothLoopingServices } from './services';
import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function Dashboard() {
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />
      <SEO 
        title="Dashboard"
        description="Точка входа в экосистему компании, единая панель и множество сервисов."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/dashboard"
        favicon={LogoDash}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>Dashboard</h1>
        <a>Точка входа в экосистему компании, единая панель и множество сервисов.</a>

        <div className={css.btns}>
          <Link className={css.connect} to={`https://dash.leecyber.com/`}>
            Попробовать сейчас
          </Link>
          <a onClick={() => setShowModal(true)}>Запросить демо</a>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/main.png'} />

        <div className={css.promoInfo}>
          <PromoBlock icon={<i class="fi fi-rr-key-skeleton-left-right" />} title="Контроль" desc="Применяйте политики безопасности и контролируйте доступ к отдельным сервисам." />
          <PromoBlock icon={<i class="fi fi-rr-shield-check" />} title="Безопасность" desc="Храним все данные на территории Российской Федерации с учётом законодательных требований." />
          <PromoBlock icon={<i class="fi fi-rr-database" />} title="Отказоустойчивость" desc="Dashboard спроектирован для работы с большим объемом задач. Он справляется с любыми нагрузками." />
        </div>
      </div>

      <InfoBlock 
        title="Вся экосистема в одной панели" 
        desc="Получите доступ ко всем сервисам экосистемы из одной панели" 
        link={''} 
        children={<SmoothLoopingServices />}  
      />

      <InfoBlock 
        title="Полный контроль и управление доступом" 
        desc="Вы можете управлять доступом как к панели, так и к определенным сервисам по группам или отдельным пользователям" 
        link={''} 
        imgSrc={AccessGif} 
      />

      <InfoBlock 
        title="Всегда под рукой" 
        desc="Панель на 100% адаптивна, вы можете получить полный доступ к своим сервисам из любой точки мира в любое время" 
        link={''} 
        imgSrc={MobileGif} 
      />

      <div className={css.pricing}>
        <PricingBlock name="Starter" price={0} desc="Для маленьких команд" children={
          <>
            <a><i class="fi fi-rr-user"></i> 5 пользователей</a>
            <a><i class="fi fi-rr-check-circle"></i> Полный доступ к панели</a>
            <a><i class="fi fi-rr-shopping-cart"></i> Корпоративный магазин</a>
            <a><i class="fi fi-rr-cloud"></i> Хранилище на 5 гб</a>
            <a><i class="fi fi-rr-check-circle"></i> Секреты, заметки и прочее</a>
          </>
        } />

        <PricingBlock name="Teams" price={4999} desc="Для небольших компаний" color="#FFF" children={
          <>
            <a><i class="fi fi-rr-users"></i> 50 пользователей</a>
            <a><i class="fi fi-rr-check-circle"></i> Полный доступ к панели</a>
            <a><i class="fi fi-rr-percent-10"></i> Скидка 10% на сервисы</a>
            <a><i class="fi fi-rr-cloud"></i> Хранилище на 100 гб</a>
            <a><i class="fi fi-rr-check-circle"></i> Секреты, заметки и прочее</a>
          </>
        } />

        <PricingBlock name="Business" price={11999} desc="Для средних компаний" color="#5776ba" borderColor="#5776ba" children={
          <>
            <a><i class="fi fi-rr-users-alt"></i> 100 пользователей</a>
            <a><i class="fi fi-rr-percent-20"></i> Скидка 20% на сервисы</a>
            <a><i class="fi fi-rr-cloud"></i> Хранилище на 1 тб</a>
            <a><i class="fi fi-rr-boss"></i> Персональный менеджер</a>
            <a><i class="fi fi-rr-user-headset"></i> Приоритетная поддержка</a>
            <a><i class="fi fi-rr-limit-speedometer"></i> Увеличенные квоты</a>
          </>
        } />

        <PricingBlock name="Enterprise" price={-1} desc="Для крупных компаний" color="#ff6969" borderColor="#ff6969" children={
          <>
            <a><i class="fi fi-rr-users-alt"></i> 500+ пользователей</a>
            <a><i class="fi fi-rr-percent-30"></i> Скидка 30% на сервисы</a>
            <a><i class="fi fi-rr-cloud"></i> ∞ Хранилище</a>
            <a><i class="fi fi-rr-boss"></i> Персональный менеджер</a>
            <a><i class="fi fi-rr-user-headset"></i> Приоритетная поддержка</a>
            <a><i class="fi fi-rr-limit-speedometer"></i> Увеличенные квоты</a>
          </>
        } />
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Как зарегистрироваться?" 
            answer="Зарегистрироваться в Dashboard можно с помощью вашего аккаунта LeeCyber.ID. После регистрации вы сможете создать организацию, чтобы объединить пользователей и управлять доступами." 
          />

          <FAQItem 
            question="Есть ли API?" 
            answer="Да, с его помощью можно интегрировать сервисы компании с другими системами." 
          />

          <FAQItem 
            question="Какой SLA у сервиса?" 
            answer="SLA на доступность сервиса Dashboard — 99,9%. Подробнее ознакомиться с условиями SLA можно в юридических документах." 
          />

          <FAQItem 
            question="Как перенести данные из других систем в Dashboard?" 
            answer="Вы можете перенести свои данные к нам при помощи API или разработанного нами инструмента миграции. Мы также можем подобрать партнёра, который проведет миграцию под ключ. Специалисты LeeCyber готовы ответить на ваши вопросы и помочь с миграцией — напишите нам." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}