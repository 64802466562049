import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import styles from './index.module.css';
import Logo from '@assets/logo.svg';

import Analytics from '@assets/logo/analytics.png';
import CA from '@assets/logo/ca.png';
import Cloud from '@assets/logo/cloud.png';
import Dash from '@assets/logo/dash.png';
import Docs from '@assets/logo/docs.png';
import Drive from '@assets/logo/drive.png';
import ID from '@assets/logo/id.png';
import Projects from '@assets/logo/projects.png';
import Shop from '@assets/logo/shop.png';
import Status from '@assets/logo/status.png';
import Weboffice from '@assets/logo/weboffice.png';
import SGtm from '@assets/logo/gtm.png';

import DemoPopup from '../demoPopup';

const Header = () => {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  const handleRedirect = () => {
    navigate(`/`);
  };

  const handleRedirectService = (id) => {
    navigate(`/${id}`);
  };

  return (
  <>
    <DemoPopup showModal={showModal} setShowModal={setShowModal} />
    <header 
      className={`${styles.leecyberKit_header} ${isSticky ? styles.sticky : ''}`}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.leecyberKit_header_body}>
        <div className={styles.leecyberKit_header_before}>
          <img className={styles.leecyberKit_header_logo} src={Logo} width={30} height={30} alt="Logo" onClick={handleRedirect} />

          <div className={styles.leecyberKit_header_menu}>
            <div 
              className={styles.leecyberKit_header_link_wrapper}
              onMouseEnter={handleMouseEnter}
            >
              <Link className={styles.leecyberKit_header_link}>
                Сервисы
              </Link>
            </div>
            <Link className={styles.leecyberKit_header_link} to={'/about'}>Компания</Link>
            <Link className={styles.leecyberKit_header_link} to={'/blog'}>Блог</Link>
            <Link className={styles.leecyberKit_header_link} to="https://docs.leecyber.com/">Документация</Link>
          </div>
        </div>

        <div className={styles.leecyberKit_header_after}>
          <Link to="https://dash.leecyber.com/">Войти</Link>
          <Link className={styles.leecyberKit_header_btn} onClick={() => setShowModal(true)}>
            Запросить демо
          </Link>
        </div>
      </div>
      {isDropdownOpen && (
        <div 
          className={styles.leecyberKit_header_dropdown}
          onMouseEnter={handleMouseEnter}
        >
          <div className={styles.leecyberKit_header_dropdown_content}>
            <a style={{ fontSize: 22, fontWeight: 500, marginLeft: 8 }}>Сервисы</a>
            
            <div className={styles.leecyberKit_header_dropdown_content_serviceList}>
                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('dashboard')}>
                  <img src={Dash} />
                  <a>Dashboard</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('projects')}>
                  <img src={Projects} />
                  <a>Projects</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('hrm')}>
                  <img src={ID} />
                  <a>Кадровый учёт</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('status')}>
                  <img src={Status} />
                  <a>Status</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('analytics')}>
                  <img src={Analytics} />
                  <a>Analytics</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('weboffice')}>
                  <img src={Weboffice} />
                  <a>WebOffice</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('cloud')}>
                  <img src={Cloud} />
                  <a>Облако</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('corpforge')}>
                  <img src={CA} />
                  <a>CorpForge</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('docs')}>
                  <img src={Docs} />
                  <a>Docs</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('corpshop')}>
                  <img src={Shop} />
                  <a>CorpShop</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('drive')}>
                  <img src={Drive} />
                  <a>Хранилище</a>
                </div>

                <div className={styles.leecyberKit_header_dropdown_content_service} onClick={() => handleRedirectService('sgtm')}>
                  <img src={SGtm} />
                  <a>GTM Containers</a>
                </div>
            </div>
          </div>
        </div>
      )}
    </header>
  </>
  );
};

export default Header;