import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import css from './index.module.css';
import Logo from '@assets/white.png';

export default function Footer() {
  return (
    <footer className={css.leecyberKit_footer}>
        <div className={css.leecyberKit_footer_main}>
            <div className={css.leecyberKit_footer_body}>
                <div className={css.colFlex}>
                    <div className={css.leecyberKit_footer_column}>
                        <h2>Сервисы</h2>
                        <Link to="/projects">Projects</Link>
                        <Link to="/status">Status</Link>
                        <Link to="/hrm">HRM</Link>
                        <Link to="/analytics">Analytics</Link>
                        <Link to="/cloud">Cloud</Link>
                        <Link to="/drive">Drive</Link>
                        <Link to="/corpforge">CorpForge</Link>
                        <Link to="/corpshop">CorpShop</Link>
                    </div>

                    <div className={css.leecyberKit_footer_column}>
                        <h2>Решения</h2>
                        <Link to="/dashboard">Dashboard</Link>
                        <Link to="/weboffice">Weboffice</Link>

                        <h2 style={{ marginTop: 25 }}>Инструменты</h2>
                        <Link to="https://docs.leecyber.com/">Документация</Link>
                        <Link to="https://docs.leecyber.com/dev">API</Link>
                        <Link to="https://docs.leecyber.com/dev">CLI</Link>
                    </div>
                </div>

                <div className={css.colFlex}>
                    <div className={css.leecyberKit_footer_column}>
                        <h2>Поддержка</h2>
                        <Link to="https://dash.leecyber.com/">Создать обращение</Link>
                        <Link to="https://docs.leecyber.com/">Помощь</Link>
                    </div>

                    <div className={css.leecyberKit_footer_column}>
                        <h2>Компания</h2>
                        <Link to="/about">О компании</Link>
                        <Link to="https://docs.leecyber.com/legal">Документы</Link>
                        <Link to="/blog">Блог</Link>
                    </div>
                </div>

                <div className={css.leecyberKit_footer_columnInfo}> 
                    <img style={{ width: 150, marginBottom: 20  }} src={Logo} />
                    <div className={css.leecyberKit_footer_contacts}>
                        <a href="mailto:info@leecyber.com">info@leecyber.com</a>
                        <a href="https://t.me/leecyberbot">@leecyberbot</a>
                        <a href="https://dash.leecyber.com/">Обращение</a>
                    </div>
                    <div className={css.leecyberKit_footer_brands}>
                        <a href="https://vk.com/leecyber"><i class="fi fi-brands-vk"></i></a>
                        <a href="https://t.me/leecyberru"><i class="fi fi-brands-telegram"></i></a>
                        <a href="https://www.youtube.com/channel/UCpIm8NdB61xM5eihlD8cs_g"><i class="fi fi-brands-youtube"></i></a>
                    </div>
                </div>
            </div>
            <div className={css.leecyberKit_footer_two}>
                <div className={css.leecyberKit_footer_two_links}>
                    <Link to={`https://docs.leecyber.com/legal/privacy`}>Политика в отношении обработки персональных данных</Link>
                    <Link to={`https://docs.leecyber.com/legal/conditions`}>Пользовательское соглашение</Link>
                </div>
                <a>Copyright © 2024 LeeCyber.</a>
            </div>
        </div>
    </footer>
  )
}